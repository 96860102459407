import { default as abuseReportszSOPXWbdfOMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93m6r6KUhbn6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index5oldJMeH7ZMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/index.vue?macro=true";
import { default as moderationGtfDl6tzwPMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/moderation.vue?macro=true";
import { default as pendingBansueobC5uHmjMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/pendingBans.vue?macro=true";
import { default as profilesOyHAbi5TudMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/profiles.vue?macro=true";
import { default as expensesdYV8MiWtsvMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexqKDXZXjSsiMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewiCSxZsmU69Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingyJVHRyKMEJMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingXWCyAXnYCcMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/translations/missing.vue?macro=true";
import { default as userstFAt1xTewiMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/admin/users.vue?macro=true";
import { default as apiCefxPynAHOMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/api.vue?macro=true";
import { default as _91slug_93uiQEPnjJ8zMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/blog/[slug].vue?macro=true";
import { default as indexLCKir6df15Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93qD5QchEb2DMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93ZZSNO4g9j7Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminKV4GsdRMI6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/census/admin.vue?macro=true";
import { default as indexjAd9t3rZqVMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/census/index.vue?macro=true";
import { default as contactHoGYM6MXP6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/contact.vue?macro=true";
import { default as designm4SSlTdPzGMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/design.vue?macro=true";
import { default as englishRRAXmTR7W6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/english.vue?macro=true";
import { default as faqCs6xzleRGEMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/faq.vue?macro=true";
import { default as inclusiveif2cwiyJozMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/inclusive.vue?macro=true";
import { default as indexShsJ2Agbi4Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/index.vue?macro=true";
import { default as licenseVMBecJM9H1Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/license.vue?macro=true";
import { default as academicOaTxrHx6o2Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/links/academic.vue?macro=true";
import { default as indexgT0lxoKxj7Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/links/index.vue?macro=true";
import { default as mediarOjv5WCh6KMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/links/media.vue?macro=true";
import { default as translinguisticsrNfv5LDZN7Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/links/translinguistics.vue?macro=true";
import { default as zine7JjuMqCIC6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/links/zine.vue?macro=true";
import { default as namesTAtdvgJDVSMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/names.vue?macro=true";
import { default as indexbDJOZop265Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/nouns/index.vue?macro=true";
import { default as templateskYUmpIxYoNMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/nouns/templates.vue?macro=true";
import { default as peopleKewvIG9WtYMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/people.vue?macro=true";
import { default as privacyAEivIDxGOTMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/privacy.vue?macro=true";
import { default as _91username_93G5wveZHCq4Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93P1B9ZSRgabMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/profile/card-[username].vue?macro=true";
import { default as editorbmOvrHVeKgMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/profile/editor.vue?macro=true";
import { default as anyTBbx4UBr6pMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/any.vue?macro=true";
import { default as askDcxhezmZ0iMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/ask.vue?macro=true";
import { default as avoidingiuiCGbqwAeMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/avoiding.vue?macro=true";
import { default as indexyrxQW1qtMzMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/index.vue?macro=true";
import { default as mirror096LXY47qAMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/mirror.vue?macro=true";
import { default as pronounuheRJ63nJcMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesopxeCviE5cMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/sources.vue?macro=true";
import { default as teamLpXyeHextiMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/team.vue?macro=true";
import { default as terminologyjpSXvPKzqaMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/terminology.vue?macro=true";
import { default as termsiWjgBio9eAMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/terms.vue?macro=true";
import { default as userExPsBGCRS6Meta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/user.vue?macro=true";
import { default as workshopslgRTBTPvhQMeta } from "/home/admin/www/pronombr.es/release/20241116155941/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportszSOPXWbdfOMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansueobC5uHmjMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiCefxPynAHOMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93uiQEPnjJ8zMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexLCKir6df15Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93qD5QchEb2DMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93ZZSNO4g9j7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminKV4GsdRMI6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexjAd9t3rZqVMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactHoGYM6MXP6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishRRAXmTR7W6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqCs6xzleRGEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveif2cwiyJozMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicOaTxrHx6o2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexgT0lxoKxj7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediarOjv5WCh6KMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsrNfv5LDZN7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine7JjuMqCIC6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesTAtdvgJDVSMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexbDJOZop265Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templateskYUmpIxYoNMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleKewvIG9WtYMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyAEivIDxGOTMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93G5wveZHCq4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93P1B9ZSRgabMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorbmOvrHVeKgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyTBbx4UBr6pMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askDcxhezmZ0iMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingiuiCGbqwAeMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexyrxQW1qtMzMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror096LXY47qAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounuheRJ63nJcMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesopxeCviE5cMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamLpXyeHextiMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyjpSXvPKzqaMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsiWjgBio9eAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userExPsBGCRS6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopslgRTBTPvhQMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241116155941/pages/workshops.vue")
  }
]